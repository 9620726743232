<template>
  <span>
    {{ ordersLimitCount }} {{ $tc("ordersLeft", ordersLimitCount).toLowerCase() }}
    <div>
      {{ getDate }}
    </div>
  </span>
</template>

<script setup>
import { computed } from "vue"

import { getFormatedDateTime } from "@/utils/getFormatedDateTime"

const props = defineProps({
  row: {
    type: Object,
    required: true
  }
})

const ordersLimitCount = computed(() => props.row.amount_free_orders || 0)
const getDate = computed(() => {
  return getFormatedDateTime(props.row.amount_free_orders_set_at)
})
</script>
