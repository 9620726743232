import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const allowedFormats = ["jpg", "jpeg", "png"]

  const allowedFormatsString = allowedFormats.join(", ")

  const requiredRule = { required: true, message: i18n.t("emptyError") }

  const nameRules = [requiredRule, { max: 250, message: i18n.t("maxLengthError", { length: 250 }) }]

  const linkRules = [requiredRule, { max: 500, message: i18n.t("maxLengthError", { length: 500 }) }]

  return {
    requiredRule,
    nameRules,
    linkRules,
    allowedFormats,
    allowedFormatsString
  }
}
