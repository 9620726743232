<template>
  <div class="seller-info">
    <a-avatar
      shape="square"
      :size="64"
      icon="shop"
      :src="parseFullImagePath"
    />

    <div>
      <a
        :href="sellerUrl"
        target="_blank"
        rel="noopener noreferrer"
        >{{ value }}</a
      >
      <div v-if="props.row?.owner_mobile_phone">+{{ props.row.owner_mobile_phone }}</div>
      <div :style="`color: ${healthStatus.color}`">{{ healthStatus.title }}</div>
    </div>
  </div>
</template>

<script setup>
import { MARKETPLACE, IMAGES_CDN } from "@/constants/common"
import getImagePath from "@/utils/getImagePath"
import useHealthStatuses from "@/composables/useHealthStatuses"
import { computed } from "vue"

const props = defineProps({
  row: {
    type: Object,
    required: true
  },
  value: {
    type: String,
    default: () => true
  }
})

const { healthStatuses } = useHealthStatuses()

const sellerUrl = computed(() => {
  return `${MARKETPLACE}/seller/${props.row.slug}/`
})
const healthStatus = computed(() => healthStatuses.value[props.row.health])

const parseFullImagePath = computed(() => {
  if (!props.row.small_logo) return ""
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(props.row.small_logo, "full")}`
})
</script>

<style lang="scss" scoped>
.seller-info {
  display: flex;
  align-items: center;
  gap: 10px;

  font-weight: 500;
}
</style>
