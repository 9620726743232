<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    style="margin-bottom: 50px"
  >
    <a-tabs v-model="activeLanguage">
      <a-tab-pane
        v-for="lang in languages"
        :key="lang"
        :tab="lang.toUpperCase()"
        forceRender
      >
        <a-form-model-item
          :label="$t('name')"
          :prop="`title.${lang}`"
        >
          <a-input v-model="form.title[lang]" />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('description')"
          :prop="`description.${lang}`"
        >
          <a-textarea v-model="form.description[lang]" />
        </a-form-model-item>
      </a-tab-pane>
    </a-tabs>

    <a-table
      :dataSource="configs.items"
      :loading="isFetching"
      :columns="columns"
      :pagination="false"
      rowKey="id"
      size="middle"
    >
      <template #title>
        <div style="display: flex; justify-content: space-between">
          <div>{{ $t("configurations") }}</div>
          <a-button
            type="primary"
            @click="addConfig()"
          >
            {{ $t("addConfiguration") }}
          </a-button>
        </div>
      </template>

      <template
        v-for="columnKey of ['expire_in_days', 'price']"
        #[columnKey]="record, _, index"
      >
        <TableEditableCellInput
          :text="record[columnKey]"
          :key="columnKey"
          @change="(value, closeEdit) => editCell(columnKey, value, record, closeEdit, index)"
        />
      </template>

      <template #active="record">
        <a-switch
          :checked="record.is_active"
          @change="toggleActivity({ is_active: !record.is_active, id: record.id })"
        />
      </template>

      <template #monthPrice="{ price, expire_in_days }">
        {{ getPricePerMounth(price, expire_in_days) }}
      </template>
    </a-table>

    <a-form-model-item
      style="margin: 0"
      prop="auto_renewal_tariff"
    >
      <a-checkbox v-model="form.auto_renewal_tariff">
        {{ $t("autoRenewTariff") }}
      </a-checkbox>
    </a-form-model-item>

    <a-form-model-item
      style="margin: 0"
      prop="is_active"
    >
      <a-checkbox
        v-model="form.is_active"
        :disabled="configs.count === 0"
      >
        {{ $t("avaliableSellerToBuy") }}
      </a-checkbox>
    </a-form-model-item>

    <a-form-model-item
      :label="$t('activeOffersCount')"
      prop="offers_count_limit"
    >
      <a-input-number
        :min="0"
        :max="999999"
        :precision="0"
        v-model="form.offers_count_limit"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("apply") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import TableEditableCellInput from "@/ant-components/TableEditableCellInput/index.js"

import {
  addTariffConfigs,
  createTariff,
  updateTariff,
  updateTariffConfig
} from "@/modules/MPAdmin/services/generalSettingService/billingTariffsService.js"

import useTariffsColumns from "../useTariffsColumns"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  prototype: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["createTariff", "updateTariff", "close"])

const languages = ["uk", "en", "ru"]

const { billingModalColumns: columns } = useTariffsColumns()

const formRef = ref()
const form = reactive({
  title: {
    uk: props.prototype.title?.uk || "",
    en: props.prototype.title?.en || "",
    ru: props.prototype.title?.ru || ""
  },
  description: {
    uk: props.prototype.description?.uk || "",
    en: props.prototype.description?.en || "",
    ru: props.prototype.description?.ru || ""
  },
  auto_renewal_tariff: Boolean(props.prototype.auto_renewal_tariff),
  is_active: Boolean(props.prototype.is_active),
  offers_count_limit: props.prototype.offers_count_limit || 0
})
const formRules = computed(() => ({
  title: {
    uk: titleRules.value,
    en: titleRules.value,
    ru: titleRules.value
  }
}))
const titleRules = computed(() => [
  { required: true, message: i18n.t("emptyError") },
  { max: 20, message: i18n.t("maxLengthError", { length: 20 }) }
])

const activeLanguage = shallowRef("uk")
const configs = reactive({
  items: props.prototype.configs || [],
  count: props.prototype.configs?.length || 0
})

const addConfig = () => {
  configs.items.push({
    expire_in_days: 0,
    price: 0,
    is_active: false,
    isNew: true,
    id: `${configs.count + 1}_${Math.random().toString(36).substr(2, 9)}`
  })
  configs.count++
}

const updateConfigs = async (tariffId) => {
  const promisesArr = []

  configs.items.forEach((item) => {
    const sendData = {
      price: item.price,
      expire_in_days: item.expire_in_days,
      is_active: item.is_active
    }

    if (item.isNew) {
      promisesArr.push(addTariffConfigs(tariffId, sendData))
    } else if (item.isChanged) {
      promisesArr.push(updateTariffConfig(tariffId, item.id, sendData))
    }
  })

  return Promise.all(promisesArr)
}

const toggleActivity = ({ is_active, id }) => {
  configs.items = configs.items.map((item) => {
    if (item.id === id) {
      item.is_active = is_active
      item.isChanged = true
    }
    return item
  })
}
const editCell = (currentKey, value, record, closeEdit, index) => {
  closeEdit()
  const { id } = record
  if (!id) {
    configs.items[index][currentKey] = value
  } else {
    configs.items[index][currentKey] = value
    configs.items[index].isChanged = true
  }
}

const getPricePerMounth = (price, expire_in_days) => {
  return +price && +expire_in_days ? Math.round((30 * price) / expire_in_days) : ""
}

const isFetching = shallowRef(false)
const onSave = async () => {
  const isValid = await formRef.value.validate().catch((valid) => valid)

  if (!isValid) return notification.error({ message: i18n.t("fillFields") })

  isFetching.value = true

  const actionData = {
    title: form.title,
    description: form.description,
    offers_count_limit: form.offers_count_limit,
    is_active: form.is_active,
    auto_renewal_tariff: form.auto_renewal_tariff
  }

  try {
    if (props.prototype.id) {
      await updateConfigs(props.prototype.id)
      const { data } = await updateTariff(actionData, props.prototype.id)
      emit("updateTariff", data)
    } else {
      const { data } = await createTariff(actionData)

      await updateConfigs(data.id)
      emit("createTariff")
    }

    notification.success({
      message: i18n.t(props.prototype.id ? "updated" : "created")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
const onClose = () => emit("close")
</script>
