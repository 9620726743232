import { API, API_V2 } from "@/utils/HttpUtils"
import i18n from "@/i18n"
import { getLocalizedHeaders, moderationPrefix } from "./servicesUtils.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import simpleBuildQuery from "@/utils/simpleBuildQuery.js"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams.js"

export const formatCategoriesTree = (categories) => {
  return categories.map((category) => {
    const { name, uuid, children } = category

    return {
      title: name,
      value: uuid,
      children: children.length > 0 ? formatCategoriesTree(children) : [],
      selectable: !children.length
    }
  })
}

export const notifyCreateProductError = (error) => {
  if (error?.response?.status === 409) {
    notifyResponseError({ error, message: i18n.t("productAlreadyExistWithThisName") })
  } else {
    notifyResponseError({ error, message: i18n.t("creationProductFail") })
  }
}

export const getTranslatedText = async (text, from, to) => {
  // Потрібен роут для Google translate
  return ""
}

export const fetchProducts = ({ queryParams, requestParams, signal }) => {
  const query = getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery)
  return API_V2.get(
    `${moderationPrefix}/products/${query}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchProductTypes = async ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/product-types/${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,

    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchProductBrands = ({ queryParams, requestParams, signal }) => {
  const query = getFormatedQueryParams(queryParams)

  return API_V2.get(
    `${moderationPrefix}/brands/${query}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchProductRu = ({ code, signal }) => {
  return API_V2.get(`${moderationPrefix}/products/${code}/`, getLocalizedHeaders("ru", signal))
}

export const fetchProductLocalized = ({ code, language = "uk", signal }) => {
  return API_V2.get(`${moderationPrefix}/products/${code}/`, getLocalizedHeaders(language, signal))
}

export const createProduct = (data) => {
  return API_V2.post(`${moderationPrefix}/products/`, data, getLocalizedHeaders("ru"))
}

export const updateProductRu = ({ code, product }) => {
  return API_V2.put(`${moderationPrefix}/products/${code}/`, product, getLocalizedHeaders("ru"))
}

export const updateProductLocalized = ({ code, product, language = "uk" }) => {
  return API_V2.put(`${moderationPrefix}/products/${code}/`, product, getLocalizedHeaders(language))
}

export const exportProduct = (productCode) => {
  return API_V2.post(`${moderationPrefix}/products/export/?code=${productCode}`)
}

export const duplicateProduct = (productCode) => {
  return API_V2.post(`${moderationPrefix}/products/${productCode}/duplicate/`)
}

export const checkExportTask = (params) => {
  return API_V2.get(`/proxy/pm/check_tasks${simpleBuildQuery(params)}`, getLocalizedHeaders("ru"))
}

export const fetchAttributesByType = ({ code, queryParams, language }) => {
  return API_V2.get(
    `${moderationPrefix}/product-types/${code}/attributes/${simpleBuildQuery(queryParams)}`,
    getLocalizedHeaders(language)
  )
}

export const approveProductTrustStatus = ({ code }) => {
  return API_V2.put(
    `${moderationPrefix}/products/${code}/`,
    { trust: "TRUSTED" },
    getLocalizedHeaders("ru")
  )
}

export const changeProductQualityMark = ({ code, qualityMark }) => {
  return API_V2.put(
    `${moderationPrefix}/products/${code}/`,
    { quality_mark: qualityMark },
    getLocalizedHeaders("ru")
  )
}

export const bulkReplaceAttributes = (form) => {
  const payload = new FormData()
  payload.append("attribute_replace_action", form.choice)
  payload.append("update_file", form.files[0])

  if (form.product_type_id) {
    payload.append("product_type_id", form.product_type_id)
  }

  return API_V2.post(
    `${moderationPrefix}/products/replace-attributes/`,
    payload,
    getLocalizedHeaders("ru")
  )
}

export const handleCheckImportStatus = async (task_id) => {
  try {
    const { data } = await API_V2.get(
      `${moderationPrefix}/products/replace-attributes/results/${task_id}/`
    )
    if (data.state === "SUCCESS") return getImportResponse(data.url)
    if (data.state === "PENDING") return setTimeout(() => handleCheckImportStatus(task_id), 5000)
  } catch (error) {
    notifyResponseError({ error })
  }
}

export const getImportResponse = async (url) => {
  const link = document.createElement("a")
  link.href = url
  link.download = `attribute_change_result.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
