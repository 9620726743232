export default {
  editMessage: "Редагувати повідомлення",
  noImages: "Немає зображень",
  sendFromBuffer: "Відправка зображень з буферу",
  sellerBilling: "Налаштування білінгу",
  sellerSettings: "Налаштування продавця",
  openBillingError: "Не вдалося відкрити білінг продавця",
  lastOption: "Останній варіант",
  noAvaliableChats: "Немає доступних чатів",
  selectChatFromList: "Виберіть чат зі списку",
  deleteMessageForAll: "Видалити це повідомлення для всіх",
  messageDeleted: "Повідомлення видалено",
  hint: "Shift + Enter для переносу рядка",
  contactSupport: "Написати в підтримку",
  messagesModeration: "Модерація повідомлень",
  firstUser: "Ім'я / id користувача 1",
  secondUser: "Ім'я / id користувача 2",
  isTyping: "набирає повідомлення",
  maxMessageLength: "Максимальна довжина повідомлення",
  formatError: "Невалідний формат файла. Дозволені формати",
  sizeError: "Розмір файлу не може перевищувати",
  quantityError: "Можна додати не більше 6 файлів",
  maximum: "Максимум",
  chatOpenedFromProduct: "Чат відкритий зі сторінки товару",

  chats: "Чати",
  message: "Повідомлення",
  new_messages: "Нові повідомлення",
  seller: "Продавець",
  attachments: "Вкладення",
  search: "Пошук",
  today: "Сьогодні",
  yesterday: "Вчора",
  error: "Помилка",
  no_chats: "Чати не знайдено",
  shop: "Магазин",
  guest: "Гість",

  edit: "Редагувати",
  delete: "Видалити",
  add_chat: "Додати чат",
  add: "Додати",
  cancel: "Скасувати",
  input_message: "Напишіть повідомлення",
  read_messages: "Прочитати",
  unread_messages: "Позначити не прочитаним",
  resend_message: "Надіслати ще раз",
  cancel_sending: "Скасувати надсилання",
  add_more: "Додати ще",
  choose_tag_to_work: "Виберіть тег в роботу",

  messages_is_empty: "Немає повідомлень",
  message_is_deleted: "Повідомлення видалено",
  no_users_found: "Користувачів не знайдено",
  nothing_found: "Даних немає",
  is_typing: "Друкує",
  is_edited: "Ред.",
  connecting: "З'єднання",
  file_too_large: "Розмір файлів, що надсилаються, мають бути менше 100 МБ",
  unsupported_format: "Формат файлу не підтримується",
  max_file_count: "Максимальна кількість файлів: 6",
  chat_list_error: "З'єднання",
  chat_opened_from: "Чат відкритий зі сторінки товару",
  year: "Років | Рік | Роки | Років",

  seller_settings: "Налаштування",
  billing_settings: "Білінг",

  get_messages_error: "Сталась помилка при завантаженні, спробуйте пізніше",
  read_messages_error: "Сталась помилка, спробуйте пізніше",
  send_message_error: "Сталась помилка при надсиланні, спробуйте пізніше",
  edit_message_error: "Сталась помилка при редагуванні, спробуйте пізніше",
  delete_message_error: "Сталась помилка при видаленні, спробуйте пізніше",
  default_error: "Сталась помилка, спробуйте пізніше",
  unread_messages_error:
    "Не знайдено доступних повідомлень для цієї дії.\n Прогорніть догори щоб завантажити попередні повідомлення.",

  reviews: {
    terrible: "Жахливо",
    bad: "Погано",
    passably: "Нормально",
    good: "Добре",
    perfect: "Чудово"
  },

  orderStatuses: {
    new: "Новий",
    inProgress: "В процесі",
    approved: "Підтверджено продавцем",
    shipped: "Відвантажено",
    closed: "Успішно закрито",
    seller_approved: "Підтверджено",
    canceled_by_customer: "Скасовано покупцем",
    canceled_by_seller: "Скасовано продавцем",
    canceled_by_supervisor: "Скасовано адміністрацією",
    canceledBy: {
      seller: "Скасовано продавцем",
      customer: "Скасовано покупцем",
      supervisor: "Скасовано адміністрацією"
    }
  }
}
