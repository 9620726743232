<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    style="margin-bottom: 50px"
  >
    <div class="label-names">
      <a-form-model-item
        :label="`${$t('text')}, UK (${form.name.uk.length}/40)`"
        prop="name.uk"
      >
        <a-input v-model="form.name.uk" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('text')}, EN (${form.name.en.length}/40)`"
        prop="name.en"
      >
        <a-input v-model="form.name.en" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('text')}, RU (${form.name.ru.length}/40)`"
        prop="name.ru"
      >
        <a-input v-model="form.name.ru" />
      </a-form-model-item>
    </div>

    <div class="label-names">
      <a-form-model-item
        :label="$t('colorFill')"
        prop="background_color"
      >
        <Sketch
          :disableAlpha="true"
          v-model="form.background_color"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('fontColor')"
        prop="font_color"
      >
        <Sketch
          :disableAlpha="true"
          v-model="form.font_color"
        />
      </a-form-model-item>

      <a-form-model-item :label="$t('example')">
        <div class="example-box">
          <LableExample
            :color="form.font_color.hex"
            :backgroundColor="form.background_color.hex"
            :value="example"
          />
        </div>
      </a-form-model-item>
    </div>

    <a-form-model-item
      :label="$t('code')"
      prop="code"
    >
      <a-input v-model="form.code" />
    </a-form-model-item>

    <a-form-model-item
      :label="`${$t('description')}: (${form.description.length}/500)`"
      prop="description"
    >
      <a-textarea
        v-model="form.description"
        :autoSize="{ minRows: 4, maxRows: 8 }"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { Sketch } from "vue-color"
import LableExample from "./LabelExample.vue"

import {
  createLabel,
  updateLabel
} from "@/modules/MPAdmin/services/generalSettingService/labelsService"
import notifyResponseError from "@/utils/notifyResponseError"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

const props = defineProps({
  prototype: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["createLabel", "updateLabel", "close"])

const formRef = ref()
const form = reactive({
  name: {
    uk: "",
    en: "",
    ru: "",
    ...props.prototype.name
  },

  background_color: {
    hex: props.prototype.background_color || "#000000"
  },
  font_color: {
    hex: props.prototype.font_color || "#FFFFFF"
  },
  code: props.prototype.code || "",
  description: props.prototype.description || ""
})
const formRules = computed(() => ({
  name: {
    uk: nameRules.value,
    en: nameRules.value,
    ru: nameRules.value
  },
  code: [{ required: true, message: i18n.t("emptyError") }]
}))
const nameRules = computed(() => [
  { required: true, message: i18n.t("emptyError") },
  { max: 40, message: i18n.t("maxLengthError", { length: 40 }) }
])

const example = computed(() => {
  return form.name.uk || form.name.en || form.name.ru || i18n.t("example")
})

const isFetching = shallowRef(false)
const onSave = async () => {
  const isValid = await formRef.value.validate().catch((valid) => valid)
  if (!isValid) return

  isFetching.value = true

  const actionData = {
    background_color: form.background_color.hex,
    font_color: form.font_color.hex,
    code: form.code,
    description: form.description,
    name: JSON.stringify(form.name)
  }

  try {
    if (props.prototype.id) {
      const { data } = await updateLabel(actionData, props.prototype.id)

      emit("updateLabel", data)
    } else {
      const { data } = await createLabel(actionData)
      emit("createLabel", data)
    }

    notification.success({
      message: i18n.t(props.prototype.id ? "updated" : "created")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
const onClose = () => emit("close")
</script>

<style lang="scss" scoped>
.example-box {
  height: 250px;
  border: 1px solid $light-border;
  border-radius: 3px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  & .example-box__lable {
    max-width: calc(100% - 16px);
  }
}
.label-names {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  &:not(:first-child) {
    height: 524px;
  }

  & > * {
    flex: 1;
  }
}
</style>
