import Vue from "vue"
import Router from "vue-router"
// TODO: temporary disable vue meta cause of composition api, update or remove it after vue 3 update
// import Meta from "vue-meta"

Vue.use(Router)
// Vue.use(Meta)

// Layout
import PageNotFound from "../components/Layout/PageNotFound"

//Default pages
import MainPage from "../components/MainPage"

// Auth
import Auth from "../modules/Auth/router"

// MP Admin
import MPAdmin from "../modules/MPAdmin/router"

// ADS
import ADS from "../modules/ADS/router"

// Messenger
import Messenger from "../modules/Messenger/router"

// Users Management
import UsersManagement from "../modules/UsersManagement/router"

// Product catalog new
import Moderation from "../modules/Moderation/router"

// Metrics
import Metrics from "../modules/Metrics/router"

const routes = [
  {
    path: "/",
    component: MainPage,
    meta: {
      code: ""
    }
  },
  {
    path: "*",
    component: PageNotFound,
    meta: {
      withoutChecking: true,
      code: ""
    }
  },
  UsersManagement,
  MPAdmin,
  ADS,
  Messenger,
  Auth,
  Moderation,
  Metrics
]

const router = new Router({
  mode: "history",
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active"
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title || "Synthetic ERP"} - Synthetic ERP`

  if (from.name !== to.name) {
    to.meta.previous = from.fullPath
  }

  next()
})

export default router
