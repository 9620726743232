<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    style="margin-bottom: 50px"
  >
    <!-- NAME -->
    <a-form-model-item
      :colon="false"
      style="margin: 0"
      prop="name.uk"
    >
      <template #label>
        {{ $t("name") }}, UA ({{ form.name.uk?.length }}/{{ nameMaxLength }})
      </template>
      <a-input v-model="form.name.uk" />
    </a-form-model-item>

    <a-form-model-item
      :colon="false"
      style="margin: 0"
      prop="name.en"
    >
      <template #label>
        {{ $t("name") }}, EN ({{ form.name.en?.length }}/{{ nameMaxLength }})
      </template>
      <a-input v-model="form.name.en" />
    </a-form-model-item>

    <a-form-model-item
      :colon="false"
      style="margin: 0"
      prop="name.ru"
    >
      <template #label>
        {{ $t("name") }}, RU ({{ form.name.ru?.length }}/{{ nameMaxLength }})
      </template>

      <a-input v-model="form.name.ru" />
    </a-form-model-item>

    <!-- DELIVERY TYPE -->
    <a-form-model-item
      :label="$t('deliveryType')"
      :colon="false"
      style="margin: 0"
      prop="type"
    >
      <a-select
        :placeholder="$t('chooseType')"
        :options="deliveryTypes"
        v-model="form.type"
        style="width: 100%"
      />
    </a-form-model-item>

    <!-- DELIVERY SERVICE -->
    <a-form-model-item
      :label="$t('deliveryService')"
      :colon="false"
      style="margin: 0"
      prop="service"
    >
      <a-select
        :placeholder="$t('chooseDeliveryService')"
        :options="deliveryServices"
        v-model="form.service"
        style="width: 100%"
      />
    </a-form-model-item>

    <!-- SORT -->
    <a-form-model-item
      :label="$t('outputSortOrder')"
      :colon="false"
      style="margin: 0"
      prop="ordering"
    >
      <a-input-number
        v-model="form.ordering"
        :min="0"
        :max="999"
        :precision="0"
      />
    </a-form-model-item>

    <!-- IS SELLER FILTERING -->
    <a-form-model-item
      :colon="false"
      style="margin: 0"
      prop="is_seller_filter"
    >
      <a-checkbox v-model="form.is_seller_filter">
        {{ $t("takeFilteringBySellers") }}
      </a-checkbox>
    </a-form-model-item>

    <!-- PAYMENT METHODS -->
    <a-form-model-item
      :label="$t('supportedPayments')"
      :colon="false"
      style="margin: 0"
      prop="payment_methods"
    >
      <a-checkbox-group
        v-model="form.payment_methods"
        class="margin-top"
        style="display: flex; flex-flow: column nowrap; gap: 8px"
      >
        <a-checkbox
          v-for="paymentMethod of filteredPaymentMethods"
          :key="paymentMethod.value"
          :value="paymentMethod.value"
          style="
            margin: 0;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          "
        >
          {{ paymentMethod.label }}
        </a-checkbox>
      </a-checkbox-group>
    </a-form-model-item>

    <!-- IS ACTIVE -->
    <a-form-model-item
      :label="$t('deliveryMethodActivity')"
      :colon="false"
      style="margin: 0"
      prop="is_active"
    >
      <a-switch
        v-model="form.is_active"
        :checkedChildren="$t('yes')"
        :unCheckedChildren="$t('no')"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        class="form-button-padding"
        @click="$emit('close')"
      >
        {{ $t("close") }}
      </a-button>
      <a-button
        type="primary"
        class="form-button-padding"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import i18n from "@/i18n"
import {
  createDeliveryMethod,
  updateDeliveryMethod
} from "@/modules/MPAdmin/services/generalSettingService/deliveryMethodsService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import { notification } from "ant-design-vue"
import { computed, reactive, ref, shallowRef } from "vue"
const props = defineProps({
  prototype: {
    type: Object,
    default: () => ({})
  },
  deliveryTypes: {
    type: Array,
    default: () => []
  },

  deliveryServices: {
    type: Array,
    default: () => []
  },

  paymentMethods: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["createMethod", "updateMethod"])

const nameMaxLength = 200
const requiredRule = computed(() => [{ required: true, message: i18n.t("fieldRequire") }])
const nameRules = computed(() => [
  ...requiredRule.value,
  {
    max: nameMaxLength,
    message: i18n.t("maxLengthError", { length: nameMaxLength })
  }
])

const formRef = ref()
const form = reactive({
  name: {
    uk: props.prototype?.name?.uk || "",
    ru: props.prototype?.name?.ru || "",
    en: props.prototype?.name?.en || ""
  },
  type: props.prototype.type ? Object.keys(props.prototype.type)?.[0] : [],
  payment_methods: props.prototype.payment_methods
    ? props.prototype.payment_methods.map((item) => item.uuid)
    : [],
  service: props.prototype.service,
  ordering: props.prototype.ordering || 0,
  is_seller_filter: Boolean(props.prototype.is_seller_filter),
  is_active: Boolean(props.prototype.is_active)
})
const formRules = computed(() => ({
  name: {
    uk: nameRules.value,
    ru: nameRules.value,
    en: nameRules.value
  },
  type: requiredRule.value,
  service: requiredRule.value,
  ordering: requiredRule.value,
  payment_methods: requiredRule.value
}))

const filteredPaymentMethods = computed(() => props.paymentMethods.filter((item) => item.is_active))

const isFetching = shallowRef(false)

const onSave = async () => {
  const isValid = formRef.value.validate().catch((valid) => valid)

  if (!isValid) return

  try {
    isFetching.value = true
    if (props.prototype.id) {
      const { data } = await updateDeliveryMethod(form, props.prototype.id)

      const payment_methods = data?.payment_methods.map((methodId) => {
        const method = props.paymentMethods.find((item) => item.value === methodId)

        if (!method) return methodId
        return { name: method.label, uuid: method.value }
      })
      emit("updateMethod", { ...data, payment_methods })
    } else {
      await createDeliveryMethod(form)
      emit("createMethod")
    }

    notification.success({
      message: i18n.t(props.prototype.id ? "updated" : "created")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>
