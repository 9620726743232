export default {
  editMessage: "Edit message",
  noImages: "No images",
  sendFromBuffer: "Sending images from buffer",
  sellerBilling: "Seller billing",
  sellerSettings: "Seller settings",
  openBillingError: "Can't open seller billing",
  lastOption: "Last option",
  noAvaliableChats: "No avaliable chats",
  selectChatFromList: "Select chat from list",
  deleteMessageForAll: "Delete this message for everyone",
  messageDeleted: "Message deleted",
  messagesModeration: "Messages moderation",
  firstUser: "First user name / id",
  secondUser: "Second user name / id",
  isTyping: "is typing",
  hint: "Shift + Enter for new line",
  contactSupport: "Contact support",
  maxMessageLength: "Maximum message length",
  formatError: "Invalid file format. Allowed formats",
  sizeError: "File size have to be less than",
  quantityError: "Maximum 6 files allowed",
  maximum: "Maximum",
  chatsNotSelected: "Chats not selected",
  chatOpenedFromProduct: "Chat is open from the product page",
  startDialogWithCustomer: "Start dialog with customer",

  chats: "Chats",
  message: "Message",
  new_messages: "New messages",
  seller: "Seller",
  attachments: "Attachments",
  search: "Search",
  today: "Today",
  yesterday: "Yesterday",
  error: "Error",
  no_chats: "Chats not found",
  shop: "Shop",
  guest: "Guest",

  edit: "Edit",
  delete: "Delete",
  add_chat: "Add chat",
  add: "Add",
  cancel: "Cancel",
  input_message: "Write the message",
  read_messages: "Read",
  unread_messages: "Mark as unread",
  resend_message: "Send again",
  cancel_sending: "Cancel sending",
  add_more: "Add more",
  choose_tag_to_work: "Виберіть тег в роботу",

  messages_is_empty: "No messages",
  message_is_deleted: "Message deleted",
  no_users_found: "No users found",
  nothing_found: "Empty",
  is_typing: "Is typing",
  is_edited: "Edited",
  connecting: "Connecting",
  file_too_large: "The size of the files you send must be less than 100 MB",
  unsupported_format: "The file format is not supported",
  max_file_count: "You can send a maximum of 6 files at a time",
  chat_list_error: "Connecting",
  chat_opened_from: "Chat opened from product page",
  year: "Year | Years",

  seller_settings: "Settings",
  billing_settings: "Billing",

  get_messages_error: "An error occurred while loading, please try again later",
  read_messages_error: "An error occurred, please try again later",
  send_message_error: "An error occurred while sending, please try again later",
  edit_message_error: "An error occurred while editing, please try again later",
  delete_message_error: "An error occurred while deleting. Try again later",
  default_error: "An error occurred, please try again later",
  unread_messages_error:
    "No available messages found for this action. Scroll up to load previous messages.",

  reviews: {
    terrible: "Жахливо",
    bad: "Погано",
    passably: "Нормально",
    good: "Добре",
    perfect: "Чудово"
  },

  orderStatuses: {
    new: "New",
    inProgress: "In progress",
    approved: "Approved by seller",
    shipped: "Shipped",
    closed: "Successfully closed",
    seller_approved: "Approved",
    canceled_by_customer: "Canceled by customer",
    canceled_by_seller: "Canceled by seller",
    canceled_by_supervisor: "Canceled by administration",
    canceledBy: {
      seller: "Canceled by seller",
      customer: "Canceled by customer",
      supervisor: "Canceled by supervisor"
    }
  }
}
