<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    :colon="false"
    style="margin-bottom: 50px"
  >
    <a-form-model-item prop="reason.uk">
      <template #label>
        {{ $t("name") }}, UA ({{ form.reason.uk?.length }}/{{ reasonMaxLength }})
      </template>
      <a-input v-model="form.reason.uk" />
    </a-form-model-item>

    <a-form-model-item prop="reason.en">
      <template #label>
        {{ $t("name") }}, EN ({{ form.reason.en?.length }}/{{ reasonMaxLength }})
      </template>
      <a-input v-model="form.reason.en" />
    </a-form-model-item>

    <a-form-model-item prop="reason.ru">
      <template #label>
        {{ $t("name") }}, RU ({{ form.reason.ru?.length }}/{{ reasonMaxLength }})
      </template>

      <a-input v-model="form.reason.ru" />
    </a-form-model-item>

    <a-form-model-item
      :label="$t('avaliableFor')"
      prop="allowed_for"
    >
      <a-select
        style="width: 100%"
        mode="multiple"
        :options="allowedForValues"
        v-model="form.allowed_for"
      />
    </a-form-model-item>

    <a-form-model-item prop="description">
      <template #label>
        {{ $t("note") }} ({{ form.description?.length }}/{{ noteMaxLength }})
      </template>
      <a-textarea v-model="form.description" />
    </a-form-model-item>

    <a-form-model-item prop="zero_quantity">
      <a-checkbox v-model="form.zero_quantity">
        {{ $t("ordersToNull") }}
      </a-checkbox>
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSave"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import {
  updateCancellationReason,
  createCancellationReason
} from "@/modules/MPAdmin/services/generalSettingService/cancellationReasonsService"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  prototype: {
    type: Object,
    default() {
      return {}
    }
  },
  allowedForValues: {
    type: Array,
    required: true
  }
})
const emit = defineEmits(["createReason", "updateReason", "close"])

const reasonMaxLength = 200
const noteMaxLength = 1000

const formRef = ref()
const form = reactive({
  reason: {
    uk: props.prototype?.reason?.ru || "",
    ru: props.prototype?.reason?.ru || "",
    en: props.prototype?.reason?.ru || ""
  },
  allowed_for: props.prototype?.allowed_for || [],
  zero_quantity: Boolean(props.prototype?.zero_quantity),
  description: props.prototype?.description || ""
})
const formRules = computed(() => ({
  reason: {
    uk: reasonRules.value,
    ru: reasonRules.value,
    en: reasonRules.value
  },
  description: descriptionRules.value,
  allowed_for: [{ required: true, message: i18n.t("emptyError") }]
}))

const reasonRules = computed(() => [
  { required: true, message: i18n.t("emptyError") },
  { max: 200, message: i18n.t("maxLengthError", { length: 200 }) }
])

const descriptionRules = computed(() => [
  { max: 1000, message: i18n.t("maxLengthError", { length: 1000 }) }
])

const isFetching = shallowRef(false)
const onSave = async () => {
  const isValid = await formRef.value.validate().catch((valid) => valid)
  if (!isValid) return

  isFetching.value = true

  try {
    if (props.prototype.id) {
      const { data } = await updateCancellationReason(form, props.prototype.id)
      emit("updateReason", data)
    } else {
      await createCancellationReason(form)
      emit("createReason")
    }

    notification.success({
      message: i18n.t(props.prototype.id ? "updated" : "created")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
const onClose = () => emit("close")
</script>
