const VALUE_ONLY_REPLACEMENT = [
  ["attr_code,value_code_from,value_codes_to"],
  ['AND-001,VLD-001,"VLD-002,VLD-003"'],
  ["AND-002,VLD-004,VLD-005"]
]

const FULL_ATTR_REPLACEMENT = [
  ["attr_code_from,value_code_from,attr_code_to,value_codes_to"],
  ["AND-001,VLD-001,AND-003,VLD-003"],
  ["AND-002,VLD-004,AND-004,VLD-001"]
]

const VALUE_REMOVAL = [["attr_code,value_code"], ["AND-001,VLD-001"], ["AND-002,VLD-004"]]

const ATTR_REMOVAL = [["attr_code"], ["AND-001"], ["AND-002"]]

export const changeFileContents = {
  VALUE_ONLY_REPLACEMENT,
  FULL_ATTR_REPLACEMENT,
  VALUE_REMOVAL,
  ATTR_REMOVAL
}
