import { API_V2 } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { MPA_PREFIX } from "./commonService"

export const fetchBillingSellers = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/billing/sellers${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const getSellersMeta = () => {
  return API_V2.get(`${MPA_PREFIX}/billing/sellers/meta`)
}

export const fetchBillingSellerById = (uuid) => {
  return API_V2.get(`${MPA_PREFIX}/billing/sellers/${uuid}`)
}

export const fetchSellerOperations = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/billing/sellers/${requestParams.seller_id}/operations${getFormatedQueryParams(
      queryParams
    )}`,
    { signal }
  )
}

export const fetchBillingSellerOrders = (uuid) => {
  return API_V2.get(`${MPA_PREFIX}/billing/sellers/${uuid}/orders-data`)
}

export const billingAddNote = (billingId, data) => {
  return API_V2.patch(`${MPA_PREFIX}/billing/sellers-bills/${billingId}`, data)
}

export const sellerChangeAmountFreeOrders = (sellerSlug, data) => {
  return API_V2.patch(`${MPA_PREFIX}/sellers/${sellerSlug}`, data)
}

export const sellerCancelTariff = (sellerUuid) => {
  let data = {
    operation_type: "TARIFF_CANCEL"
  }

  return API_V2.post(`${MPA_PREFIX}/billing/sellers/${sellerUuid}/operations`, data)
}

export const sellerSubtract = (sellerUuid, data) => {
  return API_V2.post(`${MPA_PREFIX}/billing/sellers/${sellerUuid}/operations`, {
    ...data,
    operation_type: "WRITE_OFFS"
  })
}

export const sellerChangeTariff = (sellerUuid, data) => {
  return API_V2.post(`${MPA_PREFIX}/billing/sellers/${sellerUuid}/operations`, {
    ...data,
    operation_type: "TARIFF_CHANGE"
  })
}

export const sellerReplenish = (sellerUuid, data) => {
  return API_V2.post(`${MPA_PREFIX}/billing/sellers/${sellerUuid}/operations`, data)
}
export const fetchTariffs = ({ queryParams }) => {
  return API_V2.get(`${MPA_PREFIX}/billing/tariffs${getFormatedQueryParams(queryParams)}`)
}
