import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "../commonService"

export const fetchDeliveryServices = ({ queryParams, signal }) => {
  return API_V2.get(`${MPA_PREFIX}/delivery-services${getFormatedQueryParams(queryParams)}`, {
    signal
  })
}

export const createDeliveryService = (formData) => {
  return API_V2.post(`${MPA_PREFIX}/delivery-services`, formData)
}

export const updateDeliveryService = (formData, id) => {
  return API_V2.put(`${MPA_PREFIX}/delivery-services/${id}`, formData)
}
