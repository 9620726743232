<template>
  <div>
    <a-button
      type="primary"
      icon="plus"
      @click="onModalOpen({})"
    >
      {{ $t("add") }}
    </a-button>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :columns="columns"
      :pagination="paginationInfo"
      rowKey="uuid"
      size="middle"
      :scroll="{ x: true }"
      style="margin-top: 16px"
      @change="onTableChange"
    >
      <template #actions="record">
        <a-button
          style="margin-right: 6px"
          type="dashed"
          shape="circle"
          icon="edit"
          @click="onModalOpen(record)"
        />
        <a-button
          type="danger"
          shape="circle"
          icon="delete"
          @click="onDeleteModalOpen(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :title="prototype?.uuid ? $t('editingReturnReasons') : $t('creatingReturnReasons')"
      :visible="showModal"
      destroyOnClose
      :width="600"
      @close="onModalClose"
    >
      <UpdateReturnReasonModal
        :prototype="prototype"
        @updateReason="onReasonUpdated"
        @createReason="onReasonCreated"
        @close="onModalClose"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import { Modal, notification } from "ant-design-vue"
import i18n from "@/i18n"

import UpdateReturnReasonModal from "./components/UpdateReturnReasonModal.vue"
import {
  fetchReturnReasons,
  deleteReturnReason
} from "@/modules/MPAdmin/services/generalSettingService/returnReasonsService.js"
import useRRColumns from "./useRRColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery"
import notifyResponseError from "@/utils/notifyResponseError"

const columns = useRRColumns()

const {
  dataSource,
  isFetching,

  paginationInfo,

  setupTable,
  fetchTableInfo,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchReturnReasons
})

const prototype = ref()
const showModal = ref(false)

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination })
}

const onModalOpen = (reason) => {
  showModal.value = true
  prototype.value = reason
}

const onModalClose = () => {
  showModal.value = false
  prototype.value = undefined
}

const onDeleteModalOpen = (prototype) => {
  Modal.confirm({
    title: i18n.t("returnReasonWillBeDeleted"),
    cancelText: i18n.t("cancel"),
    onOk: () => handleDeleteReason(prototype)
  })
}

const handleDeleteReason = async (prototype) => {
  try {
    await deleteReturnReason(prototype.uuid)

    notification.success({
      message: i18n.t("deleted")
    })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const onReasonUpdated = (payload) => {
  updateTableDataRecord({ identifier: "uuid", payload })
  onModalClose()
}

const onReasonCreated = () => {
  onModalClose()
  onTableChange()
}

onMounted(() => {
  setupTable({ defaultPagination: { size: "default" } })
  onTableChange()
})
</script>
