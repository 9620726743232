<template>
  <div>
    <div class="table-filters">
      <a-input-search
        v-model="filteredInfo.reason"
        :placeholder="$t('name')"
        allowClear
        @search="handleTableFilterChange()"
      >
        <template #enterButton>
          <a-button icon="search" />
        </template>
      </a-input-search>

      <a-select
        :placeholder="$t('avaliableFor')"
        mode="multiple"
        v-model="filteredInfo.allowed_for"
        :options="allowedForOptions"
        @change="handleTableFilterChange()"
      />

      <a-input-search
        v-model="filteredInfo.description"
        :placeholder="$t('note')"
        allowClear
        @search="handleTableFilterChange()"
      >
        <template #enterButton>
          <a-button icon="search" />
        </template>
      </a-input-search>

      <a-button
        icon="undo"
        @click="handleTableFiltersReset()"
      >
        {{ $t("resetFilters") }}
      </a-button>

      <a-button
        style="flex-basis: unset"
        type="primary"
        icon="plus"
        @click="openReasonModal()"
      >
        {{ $t("add") }}
      </a-button>
    </div>

    <a-table
      :dataSource="dataSource"
      :pagination="paginationInfo"
      :loading="isFetching"
      :columns="columns"
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #allowedFor="roles">
        {{ roles.map((role) => $t(role.toLowerCase())).join(", ") }}
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          icon="edit"
          shape="circle"
          @click="openReasonModal(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="showModal"
      :title="prototype?.id ? $t('editingReasonRefuse') : $t('creatingReasonRefuse')"
      :width="700"
      destroyOnClose
      @close="closeReasonModal"
    >
      <CreateCancellationReasonsModal
        :prototype="prototype"
        :allowedForValues="allowedForOptions"
        @updateReason="onReasonUpdated"
        @createReason="onReasonCreated"
        @close="closeReasonModal"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue"

import CreateCancellationReasonsModal from "./components/CreateCancellationReasonsModal.vue"

import {
  fetchCancellationReasons,
  fetchCancellationReasonsMeta
} from "@/modules/MPAdmin/services/generalSettingService/cancellationReasonsService.js"

import useCRColumns from "./useCRColumns"
import useAntTableQuery from "@/composables/useAntTableQuery"

const columns = useCRColumns()

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchCancellationReasons
})

const prototype = ref()
const showModal = ref(false)
const allowedForOptions = ref([])

const getMetadata = async () => {
  try {
    const { data } = await fetchCancellationReasonsMeta()

    if (!data.user_groups) return

    const userGroups = Object.entries(data.user_groups)
    allowedForOptions.value = userGroups.map(([value, label]) => ({
      label,
      value
    }))
  } catch {
    allowedForOptions.value = []
  }
}

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const openReasonModal = (reason) => {
  prototype.value = reason
  showModal.value = true
}

const closeReasonModal = () => {
  showModal.value = false
  prototype.value = undefined
}

const onReasonUpdated = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  closeReasonModal()
}

const onReasonCreated = () => {
  closeReasonModal()
  onTableChange()
}

onMounted(async () => {
  setupTable({ defaultPagination: { size: "default" } })
  getMetadata()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.table-filters {
  display: flex;
  gap: 16px;

  padding-bottom: 16px;

  & > * {
    flex-basis: 250px;
  }

  & > :nth-child(4) {
    flex-basis: unset;
    margin-left: auto;
  }
}
</style>
