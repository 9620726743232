<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="product-review-modal"
  >
    <a-descriptions
      bordered
      :column="1"
    >
      <a-descriptions-item :label="$t('authorFullname')">
        {{ review.author?.username }}
        {{ review.author?.first_name }}
        {{ review.author?.middle_name }}
        {{ review.author?.last_name }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('phoneNumber')">
        <a-button
          type="link"
          style="padding: 0"
          @click="handleCopy(review.author?.mobile_phone)"
        >
          +{{ review.author?.mobile_phone }}
        </a-button>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('p_code')">
        <a-button
          type="link"
          icon="link"
          style="padding: 0"
          :href="productUrl"
          target="_blank"
        >
          {{ review.p_code }}
        </a-button>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('grade')">
        {{ review.rating || 0 }}
        <a-rate
          :count="1"
          :value="review.rating"
          disabled
        />
      </a-descriptions-item>

      <a-descriptions-item :label="$t('parent')">
        <a-button
          v-if="review.parent"
          type="link"
          style="padding: 0"
          @click="handleShowParentReview()"
        >
          {{ $t("show") }}
        </a-button>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('comment')">
        {{ review.comment }}
      </a-descriptions-item>

      <a-descriptions-item :label="$t('reviewMedia')">
        <div style="display: flex; gap: 8px">
          <VideoPreviewRenderer :videos="reviewVideos" />

          <TableRendererPhotoGallery
            v-if="reviewImages?.length"
            :images="reviewImages"
          />
        </div>
      </a-descriptions-item>

      <a-descriptions-item :label="$t('isBoughtStatus')">
        <div
          v-if="review.is_bought"
          class="green-text"
        >
          <a-icon type="shopping-cart" />
          {{ $t("isBought") }}
        </div>
      </a-descriptions-item>
    </a-descriptions>

    <a-form-model-item
      :label="$t('isBoughtStatusLabel')"
      prop="is_bought"
      class="product-review-modal__select"
    >
      <a-select v-model="form.is_bought">
        <a-select-option
          v-for="option in props.isBoughtOptions"
          :key="option.value"
          :value="option.value"
        >
          <span :style="{ color: option.color }">
            {{ option.title }}
          </span>
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item
      :label="$t('moderationStatus')"
      prop="moderation_status"
      class="product-review-modal__select"
    >
      <a-select v-model="form.moderation_status">
        <a-select-option
          v-for="option in moderationStatusOptions"
          :key="option.value"
          :value="option.value"
        >
          <span :style="{ color: option.color }">
            {{ option.title }}
          </span>
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item
      v-show="form.moderation_status === 'ACTIVE'"
      :label="$t('ordering')"
      prop="ordering"
    >
      <a-input-number
        v-model="form.ordering"
        :min="0"
        class="product-review-modal__order"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref } from "vue"
import { notification, message } from "ant-design-vue"
import i18n from "@/i18n"

import { MARKETPLACE } from "@/constants/common"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"

import { updateProductReview } from "@/modules/MPAdmin/services/productReviewsService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import VideoPreviewRenderer from "./VideoPreviewRenderer.vue"
import { requiredRule } from "@/utils/validations"

const props = defineProps({
  review: {
    type: Object,
    default: () => ({})
  },
  moderationStatusOptions: {
    type: Array,
    default: () => []
  },
  isBoughtOptions: {
    type: Array,
    default: () => []
  }
})
const emit = defineEmits(["openParent", "updateReview"])

const formRef = ref()
const form = reactive({
  moderation_status: props.review.moderation_status || "",
  ordering:
    typeof props.review.ordering === "number" && props.review.moderation_status === "ACTIVE"
      ? props.review.ordering
      : "",
  is_bought: String(Boolean(props.review.is_bought))
})
const formRules = computed(() => ({
  moderation_status: requiredRule.value,
  is_bought: requiredRule.value
}))

const productUrl = computed(() => {
  return `${MARKETPLACE}/product/${props.review.slug}/${props.review.p_code}/`
})

const handleShowParentReview = () => {
  emit("openParent", props.review.parent)
}

const handleCopy = (text) => {
  navigator.clipboard.writeText(text)

  message.info(i18n.t("copied"))
}

const reviewImages = computed(
  () =>
    props.review?.attachments
      ?.filter(({ type }) => type === "IMAGE")
      .map(({ filename }) => filename) || []
)
const reviewVideos = computed(() => {
  return props.review?.attachments?.filter(({ type }) => type === "VIDEO") || []
})

const isFetching = ref(false)
const onSubmit = async () => {
  const isValid = await formRef.value.validate().catch((isValid) => isValid)
  if (!isValid) return

  try {
    isFetching.value = true

    const payload = {
      moderation_status: form.moderation_status,
      is_bought: form.is_bought === "true"
    }

    if (typeof form.ordering === "number" && form.moderation_status === "ACTIVE") {
      payload.ordering = form.ordering
    }

    const { data } = await updateProductReview(payload, props.review.id)

    emit("updateReview", data)
    notification.success({ message: i18n.t("updated") })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>

<style lang="scss" scoped>
.green-text {
  color: $dark-green-color;
}

.product-review-modal {
  padding-bottom: 50px;

  & > :first-child {
    margin-bottom: 16px;
  }

  &__select {
    width: 50%;
  }

  &__order {
    width: 130px;
  }
}
</style>

<style lang="scss">
.product-review-modal .ant-descriptions-item-label {
  width: 150px;
  text-align: center;

  font-weight: 500;
}

.product-review-modal .ant-descriptions-item-label,
.product-review-modal .ant-descriptions-item-content {
  padding: 12px;
}
</style>
