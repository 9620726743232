<template>
  <div style="margin-bottom: 40px">
    <DataTableWrapper
      :columns="columns"
      :data="tableData"
      :removeItem="removeTableItem"
      :currentCover="currentCover"
      @changeCover="handleChangeCover"
    />

    <a-form-item>
      <template #label>
        {{ data.length ? $t("productSearchByCategory", { category: data[0].category?.name }) : "" }}
      </template>

      <a-auto-complete
        v-model="searchProduct"
        :dataSource="searchResults"
        :placeholder="$t('startPrint')"
        style="width: 100%"
        @search="(e) => handleSearch(e)"
        @select="selectProduct"
      />
    </a-form-item>

    <a-form-item :label="$t('listingNumber')">
      <a-input
        :disabled="true"
        :value="nextListing"
      />
    </a-form-item>

    <DataTableWrapper
      :columns="columns"
      :data="searchTable"
      :removeItem="removeSearchTableItem"
      :currentCover="currentCover"
      @changeCover="handleChangeCover"
    />

    <a-form-item :label="$t('variations')">
      <a-select
        v-model="attrsValue"
        :options="getAllAttrs"
        :placeholder="$t('startPrint')"
        style="width: 100%"
        showSearch
        :disabled="!searchTable?.length"
        @select="selectVariation"
      />
    </a-form-item>

    <a-alert
      type="error"
      v-if="!selectedItemsContainCover"
      :message="$t('chooseProductCover')"
    />

    <a-alert
      type="error"
      v-if="!selectedAttrs.length"
      :message="$t('chooseCharacteristic')"
    />

    <a-alert
      type="error"
      v-for="error in getAttrsErrors"
      :key="error"
      :message="error"
    />

    <div v-show="selectedAttrs.length">
      <VariationsList
        :label="$t('chosenCharForVar')"
        :data="selectedAttrs"
        @change="changeVariations"
      />
    </div>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :disabled="isDisabled"
        :loading="fetching"
        @click="onSave"
      >
        {{ $t("createListing") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import DataTableWrapper from "./components/DataTableWrapper"
import VariationsList from "./components/VariationsList"

import {
  createListing,
  getLastListing,
  fetchProducts
} from "@/modules/MPAdmin/services/productsService.js"

import useProductsColumns from "../../useProductsColumns.js"
import notifyResponseError from "@/utils/notifyResponseError"

export default {
  components: {
    DataTableWrapper,
    VariationsList
  },

  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  setup() {
    const { productEditListingColumns: columns } = useProductsColumns()

    return {
      columns
    }
  },

  data() {
    return {
      tableData: [],
      searchTable: [],

      searchProduct: "",
      searchResults: [],

      selectedAttrs: [],
      attrsValue: undefined,

      nextListing: "",

      fetching: false,

      currentCover: null
    }
  },

  mounted() {
    this.tableData = this.data

    this.getNextListing()
  },

  methods: {
    async handleSearch(value) {
      if (value) {
        const { data } = await fetchProducts({
          queryParams: { name: value, category_id: this.tableData[0].category.id }
        })

        this.searchResults = data.results.map((info) => ({
          value: `${info.id}`,
          text: info.name,
          info
        }))
      } else {
        this.searchResults = []
      }
    },

    async getNextListing() {
      const { data } = await getLastListing()

      if (data && data.count) {
        this.nextListing = `${data.count + 1}`
      }
    },

    handleChangeCover(id) {
      this.currentCover = id
    },

    removeTableItem(item) {
      this.tableData = this.tableData.filter((j) => j.id !== item.id)
    },

    removeSearchTableItem(item) {
      this.searchTable = this.searchTable.filter((j) => j.id !== item.id)
    },

    selectProduct(id) {
      this.addItemToSearchTable(this.searchResults.find((item) => item.value === id).info)

      this.searchProduct = ""
      this.searchResults = []
    },

    selectVariation(variation) {
      this.handleAddVariation(variation)
      this.attrsValue = undefined
    },

    changeVariations(arr) {
      this.selectedAttrs = arr
    },

    handleAddVariation(value) {
      let newAttr = {}

      this.searchTable.forEach(({ attrs }) => {
        newAttr = attrs.find((attribute) => attribute.name.ru === value)
      })

      if (!newAttr) {
        this.$notification.error({
          message: this.$t("error"),
          description: this.$t("attributeNotFound")
        })

        return
      }

      this.selectedAttrs = [...this.selectedAttrs, { ...newAttr, name: newAttr?.name?.ru }]
    },

    addItemToSearchTable(item) {
      if (
        this.searchTable.some((j) => j.id === item.id) ||
        this.tableData.some((j) => j.id === item.id)
      ) {
        this.$notification.info({ message: this.$t("productIsChosen") })
        return
      }

      this.searchTable = [
        ...this.searchTable,
        { ...item, listing: item?.listing?.id || this.$t("no") }
      ]
    },

    async onSave() {
      this.fetching = true

      const payload = {
        cover_product: this.currentCover,
        products: [],
        variations: this.selectedAttrs.map((item) => item.name)
      }

      this.tableData.forEach((item) => payload.products.push(item.id))
      this.searchTable.forEach((item) => payload.products.push(item.id))

      try {
        await createListing(payload)

        this.$notification.success({ message: this.$t("created") })

        this.$emit("close")
      } catch (error) {
        // TODO: Check this on V2 API
        if (error?.response?.data?.detail?.length && error.response.data.detail[0]?.message) {
          error.response.data.detail = error.response.data.detail.map(({ message }) => message)

          notifyResponseError({ error })
        } else {
          notifyResponseError({ error })
        }
      } finally {
        this.fetching = false
      }
    }
  },

  computed: {
    getAttrsErrors() {
      const errors = []

      this.selectedAttrs.forEach((attr) => {
        const { name: attrName } = attr

        const notContainAttrItems = []

        notContainAttrItems.push(
          ...this.searchTable.filter((item) => {
            return item.attrs.every((attr) => {
              const localAttrName = attr.name.ru

              return localAttrName !== attrName
            })
          })
        )

        notContainAttrItems.push(
          ...this.tableData.filter((item) => {
            return item.attrs.every((attr) => {
              const localAttrName = attr.name.ru

              return localAttrName !== attrName
            })
          })
        )

        if (notContainAttrItems.length) {
          let productsPCodes = notContainAttrItems.map((item) => item.p_code).join(", ")
          let message = `${this.$t("inNextProducts", [productsPCodes])} «${attrName}»`
          errors.push(message)
        }
      })

      return errors
    },

    selectedItemsContainCover() {
      let containCover = false

      for (let i = 0; i < this.searchTable.length; i++) {
        if (this.searchTable[i].id == this.currentCover) {
          containCover = true
          break
        }
      }

      if (containCover) return containCover

      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].id == this.currentCover) {
          containCover = true
          break
        }
      }

      return containCover
    },

    isDisabled() {
      return Boolean(
        this.getAttrsErrors.length ||
          !this.selectedAttrs.length ||
          this.currentCover === null ||
          !this.selectedItemsContainCover
      )
    },

    getAllAttrs() {
      const attrs = {}

      this.searchTable.forEach((item) => {
        if (item.attrs && item.attrs.length) {
          item.attrs.forEach((attr) => {
            const key = attr.name.ru

            attrs[key] = true
          })
        }
      })

      return Object.keys(attrs).map((item) => {
        return {
          name: item,
          value: item,
          label: item
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.max-table-height {
  max-height: 400px;
}
</style>
