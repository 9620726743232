import i18n from "@/i18n"
import { computed } from "vue"

const isLowerCase = (str) => str === str.toLowerCase()
const hasWhiteSpace = (str) => str.indexOf(" ") >= 0

export const validateUppercase = (rule, value, callback) => {
  if (isLowerCase(value)) {
    callback(new Error(i18n.t("lowerCaseError")))
  } else {
    callback()
  }
}

export const validateWhiteSpace = (rule, value, callback) => {
  if (hasWhiteSpace(value)) {
    callback(new Error(i18n.t("whiteSpaseError")))
  } else {
    callback()
  }
}

export const requiredRule = computed(() => ({
  required: true,
  message: i18n.t("emptyError")
}))
