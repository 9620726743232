import { API_V2 } from "@/utils/HttpUtils"
import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { MPA_PREFIX } from "./commonService"

export const fetchLocations = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/locations${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchAllCountryLocations = () => {
  return API_V2.get(`${MPA_PREFIX}/locations?type=COUNTRY&limit=1&offset=0`) // fetch only Ukraine
}

export const fetchAllStateLocations = () => {
  return API_V2.get(`${MPA_PREFIX}/locations?type=STATE&limit=25&offset=0`) // set limit to ukrainian states total count
}

export const fetchAllDistrictLocations = () => {
  return API_V2.get(`${MPA_PREFIX}/locations?type=DISTRICT&limit=136&offset=0`) // set limit to ukrainian districts total count
}

export const fetchLocationById = (id) => {
  return API_V2.get(`${MPA_PREFIX}/locations/${id}`)
}

export const createLocation = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/locations`, payload)
}

export const updateLocation = ({ uuid, payload }) => {
  return API_V2.patch(`${MPA_PREFIX}/locations/${uuid}`, payload)
}

export const fetchPostOffices = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${MPA_PREFIX}/post-offices${getFormatedQueryParams(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const fetchPostOfficesMeta = async () => {
  const { data } = await API_V2.get(`${MPA_PREFIX}/post-offices?limit=1&offset=0`)

  return { data: data.meta }
}

export const createPostOffice = (payload) => {
  return API_V2.post(`${MPA_PREFIX}/post-offices/`, payload)
}

export const updatePostOffice = ({ uuid, payload }) => {
  return API_V2.patch(`${MPA_PREFIX}/post-offices/${uuid}`, payload)
}
