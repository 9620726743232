<template>
  <div>
    <PageHeader :title="$t('billing')">
      <template #filters>
        <a-input-search
          :placeholder="$t('search')"
          v-model="filteredInfo.search"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-search
          :placeholder="$t('note')"
          v-model="filteredInfo.bill_note"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-select
          v-model="filteredInfo.limit_exceeded_free_orders"
          :placeholder="$t('orderLimitExceeded')"
          allowClear
          @change="handleTableFilterChange()"
        >
          <a-select-option
            key="false"
            value="true"
          >
            {{ $t("yes") }}
          </a-select-option>
        </a-select>

        <InfiniteScrollSelect
          mode="multiple"
          :value="filteredInfo.tariff"
          :customRequest="fetchTariffs"
          valueKey="id"
          labelKey="title"
          onFocusFetch
          :showSearch="false"
          :placeholder="$tc('tariff')"
          @change="onTariffFilterChange"
        >
          <template #option="{ option }">{{ option.title.ru }}</template>
        </InfiniteScrollSelect>

        <a-select
          v-model="filteredInfo.bill_balance"
          :placeholder="$t('currentBalance')"
          allowClear
          @change="handleTableFilterChange()"
        >
          <a-select-option
            key="false"
            value="false"
          >
            {{ $t("negative") }}
          </a-select-option>
        </a-select>

        <a-select
          :placeholder="$t('status')"
          v-model="filteredInfo.is_active"
          allowClear
          @change="handleTableFilterChange()"
        >
          <a-select-option
            key="true"
            value="true"
          >
            {{ $t("active") }}
          </a-select-option>
          <a-select-option
            key="false"
            value="false"
          >
            {{ $t("hidden") }}
          </a-select-option>
        </a-select>

        <a-select
          :placeholder="$t('offersVisibilityTitle')"
          v-model="filteredInfo.offers_visibility"
          allowClear
          @change="handleTableFilterChange()"
        >
          <a-select-option
            key="true"
            value="ALL"
          >
            {{ $t("active") }}
          </a-select-option>
          <a-select-option
            key="false"
            value="HIDDEN"
          >
            {{ $t("hidden") }}
          </a-select-option>
        </a-select>
      </template>
      <template #actions>
        <a-button
          icon="undo"
          @click="handleTableFiltersReset()"
        >
          {{ $t("resetFilters") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      rowKey="uuid"
      :columns="columns"
      :pagination="paginationInfo"
      :loading="isFetching"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #sellerRenderer="record">
        <SellerInfoRenderer
          :row="record"
          :value="record.name"
        />
      </template>

      <template #ordersCell="record">
        <OrdersCount
          :row="record"
          :value="record.orders_count"
        />
      </template>

      <template #avaliableOrdersCell="record">
        <OrdersLimit
          :row="record"
          :value="record.amount_free_orders"
        />
      </template>

      <template #editNoteRender="record">
        <NoteRenderer
          :row="record"
          @close="onTableChange()"
        />
      </template>

      <template #tariffExpiresRenderer="record">
        <TariffExpireRenderer :row="record" />
      </template>

      <template #lastOperation="record">
        <div v-if="record.last_operation_amount">
          {{ getFormatedSum(record.last_operation_amount) }} {{ $t("uah") }}
        </div>
        <div>
          {{ getFormatedDate(record.last_operation_processed_at) }}
        </div>
      </template>

      <template #balanceRenderer="text">
        <div :class="{ 'red-text': text < 0 }">{{ getFormatedSum(text) }} {{ $t("uah") }}</div>
      </template>

      <template #statusCell="record">
        <div :class="{ 'green-text': record.is_active }">
          {{ record.is_active ? $t("active") : $t("hidden") }}
        </div>
      </template>

      <template #actions="record">
        <span style="display: flex; gap: 5px">
          <a-button
            type="dashed"
            shape="circle"
            icon="edit"
            @click="openSellerInfoModal(record)"
          />

          <a-button
            type="dashed"
            shape="circle"
            icon="shopping-cart"
            @click="goToSellerOrders(record.slug)"
          />
        </span>
      </template>
    </a-table>

    <a-drawer
      :visible="showDetailModal"
      :title="`${$t('shop')} ${selectedSeller?.name || ''}`"
      :width="1400"
      destroyOnClose
      @close="closeSellerInfoModal"
    >
      <SellerBillingModal
        :sellerUuid="selectedSeller?.uuid"
        @handleUpdateBillingInfo="onTableChange()"
        @close="closeSellerInfoModal"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"

import SellerBillingModal from "./SellerBillingModal/index.vue"
import {
  SellerInfoRenderer,
  OrdersCount,
  OrdersLimit,
  TariffExpireRenderer,
  NoteRenderer
} from "./components/index.js"

import getFormatedSum from "@/utils/getFormatedSum.js"
import { getFormatedDate } from "@/utils/getFormatedDateTime.js"

import { fetchBillingSellers, fetchTariffs } from "@/modules/MPAdmin/services/billingService.js"
import useBillingPageColumns from "./useBillingPageColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery"
import router from "@/router"
import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  setFilteredInfo
} = useAntTableQuery({
  queryFunction: fetchBillingSellers,
  requestParams: { changeRouterQuery: true }
})
const { billingListColumns: columns } = useBillingPageColumns({
  sortedInfo: computed(() => sortedInfo.value)
})

const selectedSeller = ref()
const showDetailModal = ref(false)

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const onTariffFilterChange = (value) => {
  setFilteredInfo({ ...filteredInfo.value, tariff: value })
  handleTableFilterChange()
}

const goToSellerOrders = (slug) => {
  const routeData = router.resolve({
    name: "Orders",
    query: {
      seller__slug: slug,
      ordering: "-ordered_at"
    }
  })

  window.open(routeData.href, "_blank")
}

const openSellerInfoModal = (seller) => {
  selectedSeller.value = seller
  showDetailModal.value = true
}

const closeSellerInfoModal = () => {
  showDetailModal.value = false
  selectedSeller.value = undefined
}

onMounted(async () => {
  setupTable()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.red-text {
  color: $red-color;
}
.green-text {
  color: $green-color;
}
</style>
