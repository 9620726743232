<template>
  <div>
    <PageHeader :title="$t('mainSlider')">
      <template #filters>
        <a-form-model-item :extra="$t('dateCreated')">
          <a-range-picker
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            v-model="filteredInfo.created_at__interval"
            @change="handleTableFilterChange()"
          />
        </a-form-model-item>

        <a-input-search
          v-model="filteredInfo.name"
          :placeholder="$t('name')"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-search
          v-model="filteredInfo.link"
          :placeholder="$t('url')"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-number
          v-model="filteredInfo.rating"
          :min="0"
          :placeholder="$t('ordering')"
          @change="handleTableFilterChange()"
          @pressEnter="handleTableFilterChange()"
        />
      </template>

      <template #actions>
        <a-button
          icon="undo"
          @click="handleTableFiltersReset()"
        >
          {{ $t("clearFilters") }}
        </a-button>

        <a-button
          type="primary"
          icon="plus"
          @click="openBannerInfoModal()"
        >
          {{ $t("addBanner") }}
        </a-button>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :columns="columns"
      rowKey="id"
      :pagination="paginationInfo"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #images="record">
        <TableRendererPhotoGallery :images="getBannerImagesList(record)" />
      </template>

      <template #urlRenderer="url">
        <a
          v-if="url.startsWith('http')"
          type="link"
          :href="url"
          target="_blank"
        >
          {{ url }}
        </a>

        <template v-else>
          {{ url }}
        </template>
      </template>

      <template #promoRenderer="text">
        <a-popover placement="topLeft">
          <template #content>
            <div class="popup-text">
              {{ text }}
            </div>
          </template>
          <div class="clamped-text">
            {{ text }}
          </div>
        </a-popover>
      </template>

      <template #state="isActive">
        <div :class="[isActive ? 'green-text' : 'grey-text']">
          {{ isActive ? $t("active") : $t("hidden") }}
        </div>
      </template>

      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #actions="record">
        <div style="display: flex; gap: 4px">
          <a-tooltip :title="$t('sliderEditing')">
            <a-button
              type="dashed"
              shape="circle"
              icon="edit"
              @click="openBannerInfoModal(record)"
            />
          </a-tooltip>

          <a-tooltip :title="$t('banners')">
            <a-button
              type="dashed"
              shape="circle"
              icon="desktop"
              @click="openBannerImagesModal(record)"
            />
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <a-drawer
      :visible="showEditSliderModal"
      :title="prototype?.id ? $t('sliderEditing') : $t('sliderCreating')"
      :width="800"
      destroyOnClose
      @close="closeBannerInfoModal"
    >
      <EditBannerInfo
        :showModal="showEditSliderModal"
        :prototype="prototype"
        @updateBanner="onBannerUpdate"
        @createBanner="onBannerCreate"
      />
    </a-drawer>

    <a-drawer
      :visible="showUpdateImagesModal"
      :width="1200"
      :title="$t('bannersControl')"
      destroyOnClose
      @close="closeBannerImagesModal"
    >
      <EditBannerImages
        :prototype="prototype"
        @updateImages="onImagesUpdated"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"

import EditBannerInfo from "./components/EditBannerInfo.vue"
import EditBannerImages from "./components/EditBannerImages.vue"
import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"

import { fetchPromotions } from "@/modules/MPAdmin/services/mainPromotionsService.js"

import usePromotionsPageColumns from "./usePromotionsColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import PageHeader from "@/ant-components/PageHeader/index.js"

const columns = usePromotionsPageColumns()

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchPromotions,
  requestParams: { changeRouterQuery: true }
})

const showEditSliderModal = ref(false)
const showUpdateImagesModal = ref(false)

const prototype = ref()
const prototypeId = ref()

const openBannerInfoModal = (banner) => {
  showEditSliderModal.value = true
  prototype.value = banner
}

const closeBannerInfoModal = () => {
  showEditSliderModal.value = false
  prototype.value = undefined
}

const onBannerCreate = () => {
  closeBannerInfoModal()
  onTableChange()
}

const onBannerUpdate = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  closeBannerInfoModal()
}

const openBannerImagesModal = (banner) => {
  showUpdateImagesModal.value = true
  prototype.value = banner
}

const onImagesUpdated = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  prototype.value = payload
}

const closeBannerImagesModal = () => {
  showUpdateImagesModal.value = false
  prototypeId.value = undefined
}

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const getBannerImagesList = (record) => {
  const images = record.images_by_lang

  const all_images = Object.values(images).flatMap((langImages) =>
    Object.values(langImages).flatMap((deviceImages) => Object.values(deviceImages).filter(Boolean))
  )

  return all_images
}

onMounted(() => {
  setupTable()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.grey-text {
  color: $grey-font;
}

.green-text {
  color: $green-color;
}

.clamped-text {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.popup-text {
  max-width: 400px;
  white-space: break-all;
  text-wrap: balance;
}
</style>
