import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "../commonService"

export const fetchLabels = ({ queryParams, signal }) => {
  const query = getFormatedQueryParams(queryParams).replace("#", "")
  return API_V2.get(`${MPA_PREFIX}/product-labels${query}`, { signal })
}

export const removeLabel = (payload) => {
  return API_V2.delete(`${MPA_PREFIX}/product-labels/${payload.id}`)
}

export const createLabel = (data) => {
  return API_V2.post(`${MPA_PREFIX}/product-labels`, data)
}
export const updateLabel = (data, id) => {
  return API_V2.put(`${MPA_PREFIX}/product-labels/${id}`, data)
}
