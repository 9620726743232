<template>
  <div
    class="users"
    ref="usersContainerRef"
  >
    <div
      v-for="chat in chats"
      :key="chat.uuid"
      @click.stop="onChatClick(chat)"
    >
      <ChatThumbnail
        :chat="chat"
        userRole="moderator"
      >
        <template #avatar>
          <ModeratorAvatar
            v-if="chat.owner?.logo === 'moderator'"
            class="avatar"
          />
          <AvatarPlaceholder
            v-else-if="chat.owner?.logo === 'placeholder'"
            :gender="chat.owner.gender"
            class="avatar"
          />
          <img
            v-else-if="chat.owner?.logo"
            class="avatar"
            :src="getImgUrl(chat.owner.logo)"
          />
        </template>
      </ChatThumbnail>
    </div>

    <div
      v-if="!chats.length && !isFetching"
      class="chat-list-wrapper__no-chats"
      style="margin-top: 16px"
    >
      {{ $t("no_chats") }}
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue"
import { ChatThumbnail, ModeratorAvatar, AvatarPlaceholder } from "@synthetic_ua/messenger-front"

import { globalChatSearch } from "@/modules/Messenger/messengerService.js"

import { IMAGES_CDN } from "@/constants/common.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import getImagePath from "@/utils/getImagePath.js"

const props = defineProps({
  currentChatId: {
    type: String,
    default: ""
  },
  form: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["openChat"])

const usersContainerRef = ref()

const isFetching = ref(false)
const chats = ref([])
const hasNextPage = ref(true)

const onChatClick = (chat) => {
  emit("openChat", chat)
}

const onScrollBottom = () => {
  if (isFetching.value) return

  const { scrollTop, scrollHeight, clientHeight } = usersContainerRef.value
  const isNotScrolled = scrollTop === 0

  if (isNotScrolled) return

  const scrolledToBottom = scrollTop >= scrollHeight - clientHeight - 50

  if (scrolledToBottom && hasNextPage.value) {
    getChats()
  }
}

const getChats = async () => {
  if (!props.form.firstInput && !props.form.secondInput) {
    return resetChats()
  }

  try {
    isFetching.value = true

    const participant = [props.form.firstInput, props.form.secondInput].filter(Boolean).join(",")

    const { data, config } = await globalChatSearch({
      participant,
      limit: 15,
      offset: chats.value.length
    })

    if (!data.count) {
      return resetChats()
    }

    const currentChat = chats.value.find((c) => c.uuid === props.currentChatId)
    const currentChatMessages = currentChat ? currentChat.messages : []

    const newChats = data.results.map((item) => {
      let messages = []

      if (item.uuid === props.currentChatId) {
        messages = currentChatMessages
      }

      return {
        ...item,
        id: item.uuid,
        user: {
          online: item.owner.is_online,
          name: item.owner.username
        },
        messages,
        offset: 0
      }
    })

    if (chats.value.length) {
      chats.value.push(...newChats)
    } else {
      chats.value = newChats
    }

    const totalFetchedChats = config.params.limit + config.params.offset
    hasNextPage.value = data.count - totalFetchedChats > 0
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const resetChats = () => {
  chats.value = []
}

const getImgUrl = (image, size = "full") => {
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(image, size)}`
}

onMounted(() => {
  usersContainerRef.value.addEventListener("scroll", onScrollBottom, false)
})

onBeforeUnmount(() => {
  usersContainerRef.value?.removeEventListener("scroll", onScrollBottom, false)
})

defineExpose({
  getChats,
  resetChats
})
</script>

<style lang="scss" scoped>
.users {
  display: flex;
  flex-direction: column;

  padding-right: 4px;

  overflow-y: scroll;
  overflow-x: hidden;
}

.avatar {
  &--moderator {
    background-image: url("@/assets/images/logo.png");
    height: 100%;
    width: 100%;
  }

  &--default {
    background-image: url("@/assets/images/user/avatar-male.svg");
    background-repeat: 1;
    height: 100%;
    width: 100%;
  }
}
</style>
