<template>
  <div>
    <PageHeader :title="$t('productsReviews')">
      <template #filters>
        <a-range-picker
          v-model="filteredInfo.created_at__interval"
          format="DD.MM.YYYY"
          valueFormat="YYYY-MM-DD"
          style="margin-top: 3px"
          @change="handleTableFilterChange()"
        />

        <a-input-number
          :placeholder="$t('authorID')"
          v-model="filteredInfo.customer_id"
          allowClear
          @pressEnter="handleTableFilterChange()"
        />

        <a-input-search
          v-for="field in searchFields"
          :placeholder="$t(field.text)"
          v-model="filteredInfo[field.key]"
          :key="field.key"
          allowClear
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-select
          v-model="filteredInfo.is_bought"
          :placeholder="$t('isBought')"
          allowClear
          @change="handleTableFilterChange()"
        >
          <a-select-option
            v-for="option in isBoughtOptions"
            :key="option.value"
            :value="option.value"
          >
            <span :style="{ color: option.color }">
              {{ option.title }}
            </span>
          </a-select-option>
        </a-select>
      </template>

      <template #actions>
        <div class="table-header__footer__actions">
          <a-button
            icon="undo"
            @click="handleTableFiltersReset()"
          >
            {{ $t("clearFilters") }}
          </a-button>
          <a-select
            v-model="filteredInfo.moderation_status"
            :placeholder="$t('moderationStatus')"
            size="large"
            style="width: 200px"
            allowClear
            @change="handleTableFilterChange()"
          >
            <a-select-option
              v-for="option in moderationStatusOptions"
              :key="option.value"
              :value="option.value"
            >
              <span :style="{ color: option.color }">
                {{ option.title }}
              </span>
            </a-select-option>
          </a-select>
        </div>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="paginationInfo"
      :loading="isFetching"
      rowKey="id"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #pCode="record">
        <a
          :href="productUrl(record)"
          target="_blank"
        >
          {{ record.p_code }}
        </a>
      </template>

      <template #contacts="author">
        <UserContacts
          :email="author.email"
          :mobilePhone="author.mobile_phone"
        />
      </template>

      <template #ratingRenderer="value">
        {{ value || "0" }}
        <a-rate
          :count="1"
          :value="value"
          disabled
        />
      </template>

      <template #comment="value, record">
        <Comment
          :value="value"
          :isBought="record.is_bought"
        />
      </template>

      <template #imageRenderer="images">
        <TableRendererPhotoGallery :images="images" />
      </template>

      <template #mediaRenderer="attachments">
        <div class="media-cell">
          <template v-if="getVideoArray(attachments)?.length">
            <a-popover
              trigger="hover"
              v-for="(video, index) in getVideoArray(attachments)"
              :key="`${video.filename}_${index}`"
            >
              <template #content>
                <div class="video-attachment__popover">
                  {{ $t("poster") }}:
                  <a
                    :href="getVideoPoster(video.video_thumbnail)"
                    target="blank"
                  >
                    {{ getVideoPoster(video.video_thumbnail) }}
                  </a>
                  <br />

                  {{ $t("video") }}:
                  <a
                    :href="getVideoPath(video.filename)"
                    target="blank"
                  >
                    {{ getVideoPath(video.filename) }}
                  </a>
                </div>
              </template>
              <div style="position: relative; cursor: pointer">
                <a-icon
                  class="video-interact-button"
                  type="play-circle"
                  theme="filled"
                />
                <img
                  alt=""
                  style="width: 50px; height: 50px; object-fit: cover"
                  :src="getVideoPoster(video.video_thumbnail)"
                />
              </div>
            </a-popover>
          </template>

          <TableRendererPhotoGallery
            v-if="attachments?.length && getImagesArray(attachments)?.length"
            :images="getImagesArray(attachments)"
          />
        </div>
      </template>

      <template #statusRender="value">
        <ModerationStatus :status="value" />
      </template>

      <template #parent="value">
        <a-button
          v-if="value"
          type="link"
          style="padding: 0"
          @click="handleShowParentModal(value)"
        >
          {{ $t("show") }}
        </a-button>
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          icon="edit"
          shape="circle"
          @click="openReviewModal(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :width="700"
      :visible="showParentReviewModal"
      :title="$t('parentReview')"
      destroyOnClose
      @close="showParentReviewModal = false"
    >
      <ParentReviewModal :parent="parentReview" />
    </a-drawer>

    <a-drawer
      :visible="showEditReview"
      :title="$t('edit')"
      :width="800"
      destroyOnClose
      @close="closeReviewModal"
    >
      <EditProductReviewModal
        :review="selectedReview"
        :moderationStatusOptions="moderationStatusOptions"
        :isBoughtOptions="isBoughtOptions"
        @openParent="handleShowParentModal"
        @updateReview="onUpdateReview"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue"
import i18n from "@/i18n"

import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import UserContacts from "../renderers/UserContacts.vue"
import { ParentReviewModal, EditProductReviewModal, Comment } from "./components/index.js"
import ModerationStatus from "../renderers/ModerationStatus.vue"
import PageHeader from "@/ant-components/PageHeader/index.js"

import usePRPageColumns from "./usePRPageColumns.js"

import { fetchProductReviews } from "@/modules/MPAdmin/services/productReviewsService.js"

import useAntTableQuery from "@/composables/useAntTableQuery"
import { IMAGES_CDN, MARKETPLACE } from "@/constants/common.js"
import { useStatuses } from "@/composables/useStatuses"
import getImagePath from "@/utils/getImagePath"

// metaInfo: {
//   title: "Відгуки про товари"
// },

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchProductReviews,
  requestParams: { changeRouterQuery: true }
})

const columns = usePRPageColumns({ sortedInfo: computed(() => sortedInfo.value) })

const showEditReview = ref(false)
const selectedReview = ref()

const showParentReviewModal = ref(false)
const parentReview = ref(0)

const { orderModerationStatuses } = useStatuses()
const moderationStatusOptions = computed(() => Object.values(orderModerationStatuses.value))
const isBoughtOptions = computed(() => [
  { title: i18n.t("yes"), value: "true", color: "#52C41A" },
  { title: i18n.t("no"), value: "false", color: "#FF4D4F" }
])

const searchFields = [
  {
    key: "customer_username",
    text: "user"
  },
  {
    key: "customer_contacts",
    text: "contacts"
  },
  {
    key: "comment",
    text: "comment"
  },
  {
    key: "p_code",
    text: "p_code"
  }
]

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const handleShowParentModal = (parent = null) => {
  showParentReviewModal.value = !showParentReviewModal.value
  parentReview.value = parent
}

const openReviewModal = (review) => {
  selectedReview.value = review
  showEditReview.value = true
}

const closeReviewModal = () => {
  showEditReview.value = false
  selectedReview.value = undefined
}

const onUpdateReview = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  closeReviewModal()
}

const productUrl = (record) => {
  return `${MARKETPLACE}/product/${record?.slug}/${record?.p_code}/`
}

const getImagesArray = (images) => {
  if (!images || !images.length) return []
  if (images.some((item) => item.type)) {
    return images?.filter(({ type }) => type === "IMAGE")?.map(({ filename }) => filename)
  }

  return images
}

// VIDEOS
const getVideoPath = (filename) => {
  return `${IMAGES_CDN}/media/assets/videos/${getImagePath(filename, "full")}`
}

const getVideoPoster = (path) => {
  if (!path) return ""
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(path, "full")}`
}

const getVideoArray = (attachments) => {
  if (attachments.some(({ type }) => type)) {
    return attachments.filter(({ type }) => type === "VIDEO")
  }
  return []
}

onMounted(() => {
  setupTable()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.table-header__footer__actions {
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
}

.clamped-comment {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: help;
}

.media-cell {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 8px;

  width: 166px;
  max-width: 100%;
}

.video-attachment {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;

  &__popover {
    max-width: 300px;
  }
}

.video-interact-button {
  position: absolute;
  top: 0;
  left: 0;

  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.7);
  font-size: 20px;

  & + img {
    width: 50px;
    height: 50px;
  }
}

.video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  background: $black;

  & > video {
    height: 80%;
    max-width: 80%;

    object-fit: contain;
    background-color: $font-color;
    border-radius: 16px;
  }
}
</style>
