<template>
  <span :class="{ 'limit-is-exceeded': limitIsExceeded }">
    {{ props.value }}
  </span>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  row: {
    type: Object,
    required: true
  },
  value: {
    type: Number,
    default: 0
  }
})

const limitIsExceeded = computed(() => {
  const ordersLimitCount = props.row.amount_free_orders || 0

  return !props.row.tariff_config && props.value >= ordersLimitCount
})
</script>

<style lang="scss" scoped>
.limit-is-exceeded {
  color: $red-color;
}
</style>
