<template>
  <div v-if="row.tariff_config">
    <div>
      {{
        `${row.tariff_config.tariff.title} / ${row.tariff_config.expire_in_days} ${$t(
          "dayShorted"
        )}`
      }}
    </div>
    <div
      v-if="getDateAlert(row.tariff_expire_at)"
      class="alert"
    >
      {{ `${$t(getDateAlert(row.tariff_expire_at))} ${getFormatedDateTime(row.tariff_expire_at)}` }}
    </div>
    <div v-else>{{ getFormatedDateTime(row.tariff_expire_at) }}</div>
  </div>
</template>

<script setup>
import { getFormatedDateTime } from "@/utils/getFormatedDateTime"

const props = defineProps({
  row: {
    type: Object,
    required: true
  }
})
const getDateAlert = (value) => {
  const today = new Date()
  const date = new Date(value)

  const Diff_In_Time = date.getTime() - today.getTime()
  const diff_in_days = Math.ceil(Diff_In_Time / (1000 * 3600 * 24))

  if (diff_in_days <= 0) {
    return "isOver"
  } else if (diff_in_days <= 5) {
    return "willOver"
  }
  return ""
}
</script>

<style lang="scss" scopen>
.alert {
  color: $red-color;
}
</style>
