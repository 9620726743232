import getFormatedQueryParams from "@/utils/getFormatedQueryParams"
import { API_V2 } from "@/utils/HttpUtils"
import { MPA_PREFIX } from "./commonService"

export const fetchBuyers = ({ queryParams, requestParams, signal }) => {
  const params = getFormatedQueryParams(queryParams, requestParams?.changeRouterQuery).replace(
    "is_confirmed_email=false",
    "has_email=true"
  )

  return API_V2.get(`${MPA_PREFIX}/customers${params}`, { signal })
}

export const updateBuyer = (uuid, payload) => {
  return API_V2.patch(`${MPA_PREFIX}/customers/${uuid}`, payload)
}
