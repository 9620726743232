import { render, staticRenderFns } from "./EditType.vue?vue&type=template&id=693bb346&scoped=true"
import script from "./EditType.vue?vue&type=script&setup=true&lang=js"
export * from "./EditType.vue?vue&type=script&setup=true&lang=js"
import style0 from "./EditType.vue?vue&type=style&index=0&id=693bb346&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "693bb346",
  null
  
)

export default component.exports