<template>
  <div class="new-order-limit">
    {{ $t("newOrderLimit") }}
    <a-input-number
      style="width: 100%"
      :min="0"
      :precision="0"
      v-model="newFreeOrders"
    />

    <div class="new-order-limit__buttons">
      <a-button
        :disabled="isFetching"
        @click="clearAmountFreeOrdersDropdown"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        @click="changeAmountFreeOrders"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import i18n from "@/i18n"
import { sellerChangeAmountFreeOrders } from "@/modules/MPAdmin/services/billingService.js"
import notifyResponseError from "@/utils/notifyResponseError"
import { notification } from "ant-design-vue"
import { shallowRef } from "vue"

const props = defineProps({
  amountFreeOrders: {
    type: Number | String,
    default: 0
  },
  slug: {
    type: String,
    required: true
  },
  uuid: {
    type: String,
    required: true
  }
})
const emit = defineEmits(["clearAmountFreeOrdersDropdown"])

const newFreeOrders = shallowRef(+props.amountFreeOrders || 0)
const isFetching = shallowRef(false)

const changeAmountFreeOrders = async () => {
  isFetching.value = true

  try {
    await sellerChangeAmountFreeOrders(props.slug, { amount_free_orders: newFreeOrders.value })

    notification.success({ message: i18n.t("freeOrdersLimitChanged") })
    emit("handleUpdateBillingInfo")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const clearAmountFreeOrdersDropdown = () => {
  newFreeOrders.value = props.amountFreeOrders
}
</script>

<style scoped lang="scss">
.new-order-limit {
  width: 30%;
  margin-left: 32px;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  &__buttons {
    display: flex;
    justify-content: space-between;
    & > * {
      width: 48%;
    }
  }
}
</style>
