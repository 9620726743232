import { computed, h } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo }) => {
  const columns = computed(() => [
    {
      key: "created_at",
      dataIndex: "created_at",
      title: i18n.t("reviewDate"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "created_at" && sortedInfo?.value?.order,
      fixed: "left",
      width: 105
    },
    {
      key: "p_code",
      title: i18n.t("p_code"),
      scopedSlots: {
        customRender: "pCode"
      },
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "p_code" && sortedInfo?.value?.order,
      fixed: "left",
      width: 115
    },
    {
      key: "author_id",
      dataIndex: "author.id",
      title: i18n.t("authorID"),
      width: 130,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "author_id" && sortedInfo?.value?.order
    },
    {
      key: "customer_username",
      dataIndex: "author.username",
      title: i18n.t("user"),
      width: 180
    },
    {
      key: "customer_contacts",
      dataIndex: "author",
      title: h("div", ["Email", h("br"), i18n.t("phoneNumber")]),
      scopedSlots: {
        customRender: "contacts"
      },
      width: 230
    },
    {
      key: "rating",
      dataIndex: "rating",
      title: i18n.t("grade"),
      scopedSlots: {
        customRender: "ratingRenderer"
      },
      width: 100,
      align: "center",
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "rating" && sortedInfo?.value?.order
    },
    {
      key: "comment",
      dataIndex: "comment",
      title: i18n.t("comment"),
      scopedSlots: {
        customRender: "comment"
      },
      width: 270
    },
    {
      key: "videos",
      dataIndex: "attachments",
      title: i18n.t("reviewMedia"),
      scopedSlots: {
        customRender: "mediaRenderer"
      },
      width: 198
    },
    {
      key: "product_images",
      dataIndex: "product_images",
      title: i18n.t("productImages"),
      width: 85,
      scopedSlots: {
        customRender: "imageRenderer"
      }
    },
    {
      key: "ordering",
      dataIndex: "ordering",
      title: i18n.t("sorting"),
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "ordering" && sortedInfo?.value?.order,
      width: 90
    },
    {
      key: "parent",
      dataIndex: "parent",
      title: i18n.t("parent"),
      scopedSlots: {
        customRender: "parent"
      },
      width: 95
    },
    {
      key: "moderation_status",
      dataIndex: "moderation_status",
      title: i18n.t("moderationStatus"),
      scopedSlots: {
        customRender: "statusRender"
      },
      fixed: "right",
      width: 150
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actions"
      },
      fixed: "right",
      width: 65
    }
  ])

  return columns
}
