import { render, staticRenderFns } from "./CancellationReasons.vue?vue&type=template&id=6581d600&scoped=true"
import script from "./CancellationReasons.vue?vue&type=script&setup=true&lang=js"
export * from "./CancellationReasons.vue?vue&type=script&setup=true&lang=js"
import style0 from "./CancellationReasons.vue?vue&type=style&index=0&id=6581d600&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6581d600",
  null
  
)

export default component.exports