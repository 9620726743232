<template>
  <div>
    <a-page-header
      class="table-header"
      :title="`${$t('tagEditing')}: ${title} / Slug: ${slug}`"
      @back="handleReturnToTags"
    >
      <template #extra>
        <a-button
          key="cancel"
          @click="handleReturnToTags"
        >
          {{ $t("cancel") }}
        </a-button>

        <a-button
          key="save"
          type="primary"
          :loading="loading"
          @click="onSubmit"
        >
          {{ $t("save") }}
        </a-button>
      </template>

      <template #footer>
        <div>
          <a-tabs
            v-model="activeTab"
            style="height: 60px"
          >
            <a-tab-pane key="settings">
              <template #tab><a-icon type="setting" />{{ $t("settings") }}</template>
            </a-tab-pane>

            <a-tab-pane key="SEO">
              <template #tab>
                <a-icon type="bar-chart" />
                SEO-description
              </template>
            </a-tab-pane>
          </a-tabs>

          <a-spin
            :spinning="activityIsFetching || loading"
            class="activity-card"
          >
            <a-form-item
              :label="$t('tagActivity')"
              class="activity-card__item"
            >
              <a-radio-group
                :value="tagInfo?.is_active"
                :disabled="activityIsFetching"
                @change="onActivityChange"
              >
                <a-radio-button :value="false">{{ $t("inactive") }}</a-radio-button>
                <a-radio-button :value="true">{{ $t("active") }}</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-spin>
        </div>
      </template>
    </a-page-header>

    <template v-if="tagInfo">
      <a-spin :spinning="loading">
        <Settings
          ref="settings"
          v-show="activeTab === 'settings'"
          :tag="tagInfo"
          :tagIsEdited="tagIsEdited"
          @setEdited="setTagIsEdited"
        />
        <SEOdescription
          ref="seoDescription"
          v-show="activeTab === 'SEO'"
          :tag="tagInfo"
          :tagIsEdited="tagIsEdited"
          @setEdited="setTagIsEdited"
        />
      </a-spin>
    </template>
  </div>
</template>

<script>
import i18n from "@/i18n"
import SEOdescription from "./_components/SEOdescription.vue"
import Settings from "./_components/Settings.vue"

import { fetchSingleTag, editTagActivity, updateTag } from "@/modules/MPAdmin/services/tagsService"

export default {
  name: "EditTagPage",

  components: {
    Settings,
    SEOdescription
  },

  data() {
    return {
      title: "",
      slug: "",
      activeTab: "settings",
      tagInfo: null,
      loading: false,
      fetching: true,
      tagIsEdited: false,
      activityIsFetching: false
    }
  },

  mounted() {
    this.getTag()
    this.tagIsEdited = false
  },

  methods: {
    async getTag() {
      this.fetching = true

      try {
        const { data } = await fetchSingleTag(this.$route.params.uuid)

        this.tagInfo = data
        this.title = data.title
        this.slug = data.slug
      } catch (e) {
        this.$notification.error({
          message: e.response.data
        })
        this.$router.push("/mp-admin/offer-tags")
      } finally {
        this.fetching = false
      }
    },

    handleReturnToTags() {
      if (this.tagIsEdited) {
        this.$confirm({
          title: this.$t("changesNotSaved"),
          content: this.$t("leavePage"),
          cancelText: this.$t("no"),
          okText: this.$t("yes"),
          onOk: this.returnToTagsPage
        })
      } else {
        this.returnToTagsPage()
      }
    },

    returnToTagsPage() {
      if (/offer-tags\/?\?/.test(this.$route.meta.previous || "")) {
        this.$router.push(this.$route.meta.previous)
      } else {
        this.$router.push({ name: "TagsList" })
      }
    },

    async onSubmit() {
      const settingsValues = await this.$refs.settings.getSettingsFormValues()
      const seoValues = await this.$refs.seoDescription.getSeoFormValues()

      if (!settingsValues) {
        this.$message.error(i18n.t("fillNameAndSlug"))
        return
      }
      this.loading = true

      const data = {
        ...settingsValues,
        ...seoValues,
        _note: settingsValues._note || null,
        slug: this.slug === settingsValues.slug ? undefined : settingsValues.slug
      }

      try {
        await updateTag(data, this.$route.params.uuid)

        this.$notification.success({
          message: this.$t("changesSaved")
        })
        this.tagIsEdited = false
        this.$refs.settings.disableSlugInput()
      } catch (e) {
        for (const key in e.response.data) {
          this.$notification.error({
            message: key + ": " + e.response.data[key]
          })
        }
      } finally {
        this.loading = false
      }
    },

    async onActivityChange(e) {
      this.activityIsFetching = true

      try {
        await editTagActivity({ is_active: e.target.value }, this.tagInfo.uuid)

        this.tagInfo.is_active = e.target.value
        this.$notification.success({
          message: this.$t("tagActivityChanged")
        })
      } catch (e) {
        const isSeoError = e.response?.data?.detail?.some((error) => error.includes("seo"))

        if (isSeoError) {
          this.$notification.error({
            message: this.$t("seoBlankError")
          })
        } else {
          this.$notification.error({
            message: e.response.data.detail
          })
        }
      } finally {
        this.activityIsFetching = false
      }
    },

    setTagIsEdited() {
      this.tagIsEdited = true
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-card {
  position: absolute;
  top: 64px;
  right: 0;

  width: 360px;
  margin-left: auto;
  height: fit-content;
  padding: 10px;

  border: 1px solid $border-color;
  border-radius: 2px;

  &__item {
    display: flex;
    align-items: center;
    height: fit-content;
    justify-content: space-between;
    margin: 0;
  }
}
</style>
