<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    style="margin-bottom: 50px"
  >
    <a-form-model-item
      :label="`${$t('name')}, RU`"
      prop="name.ru"
    >
      <a-input v-model="form.name.ru" />
    </a-form-model-item>

    <a-form-model-item
      :label="`${$t('name')}, EN`"
      prop="name.en"
    >
      <a-input v-model="form.name.en" />
    </a-form-model-item>

    <a-form-model-item
      :label="`${$t('name')}, UK`"
      prop="name.uk"
    >
      <a-input v-model="form.name.uk" />
    </a-form-model-item>

    <a-form-model-item :label="$t('outputSortOrder')">
      <a-input-number
        v-model="form.ordering"
        :min="0"
        :max="999999"
        :precision="0"
      />
    </a-form-model-item>

    <a-form-model-item :label="$t('help')">
      <a-textarea
        v-model="form.help"
        :placeholder="$t('hint')"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        :disabled="isFetching"
        @click="onClose"
      >
        {{ $t("cancel") }}
      </a-button>
      <a-button
        type="primary"
        :loading="isFetching"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import {
  createReturnReason,
  updateReturnReason
} from "@/modules/MPAdmin/services/generalSettingService/returnReasonsService"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  prototype: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["createReason", "updateReason", "close"])

const formRef = ref()
const form = reactive({
  name: {
    uk: props.prototype?.name?.uk || "",
    en: props.prototype?.name?.en || "",
    ru: props.prototype?.name?.ru || ""
  },
  ordering: Number(props.prototype?.ordering) || 0,
  help: props.prototype?.help || ""
})
const formRules = computed(() => ({
  name: {
    uk: nameRules.value,
    en: nameRules.value,
    ru: nameRules.value
  },
  help: helpRules.value
}))

const nameRules = computed(() => [
  { required: true, message: i18n.t("emptyError") },
  { min: 2, message: i18n.t("minLengthError", { length: 2 }) },
  { max: 250, message: i18n.t("minLengthError", { length: 250 }) }
])
const helpRules = computed(() => [{ max: 250, message: i18n.t("minLengthError", { length: 250 }) }])

const isFetching = shallowRef(false)
const onSubmit = async () => {
  const isValid = await formRef.value.validate().catch((valid) => valid)
  if (!isValid) return

  isFetching.value = true

  try {
    if (props.prototype.uuid) {
      const { data } = await updateReturnReason(form, props.prototype.uuid)
      emit("updateReason", data)
    } else {
      await createReturnReason(form)
      emit("createReason")
    }
    notification.success({
      message: i18n.t(props.prototype.uuid ? "updated" : "created")
    })
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
const onClose = () => emit("close")
</script>
